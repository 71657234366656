import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import { usePlans } from "../hooks/usePlans"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import CompareHeader from "../components/compareComponents/CompareHeader"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header5 from "../components/layout/textFormatingComponents/Header5"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import Button from "../components/layout/Button"
import { Link } from "gatsby"
import OperatorImage from "../components/images/OperatorImage"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import MacAd from "../components/markdownComponents/antivirusLink/internalAds/MacAd"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = usePlans(data.allPlansJson.nodes)
  return (
    <GlobalStyles>
      <MetaDetails
        title="Halvin puhelinliittymä netillä 2020 - Katso edullisin!"
        desc="Edullinen puhelinliittymä on helppoa löytää, katso vaikka täältä. Muutaman minuutin työllä voit alkaa säästämään kymmeniä euroja kuukaudessa jo vaikka tänään."
      />
      <CompareHeader
        plansHook={plansHook}
        pageName={"etusivu"}
        title={"Löydä halvin puhelinliittymä vuodelle 2022"}
        slogan={
          "Vertaa nopeasti sinulle edullisin liittymä vastaamalla vain 3 kysymykseen!"
        }
      />
      <AdserviceCompare />
      <Container>
        <MacAd />
      </Container>
      <RowContainer title={"Tutustu myös sivustomme muuhun tarjontaan:"}>
        <Button link={"/operaattorit/"} title={"Operaattorit"} />
        <Button link={"/mokkula/"} title={"Mokkula"} />
        <Button link={"/nettiliittyma/"} title={"Nettiliittymävertailu"} />
        <Button
          link={"/puhelinliittymavertailu/"}
          title={"Puhelinliittymävertailu"}
        />
        <Button link={"/liittymatarjous/"} title={"Liittymä tarjoukset"} />
      </RowContainer>
      <Container>
        <Header2>Kaikki mitä olet halunnut tietää puhelinliittymistä </Header2>
        <TextArea>
          Puhelinliittymiä vertaillessa voi olla vaikeaa päättää, mikä olisi
          itselle se paras ja tietysti samalla <b>halvin liittymä</b>.
          Markkinoilla on useita operaattoreita, jotka kaikki mainostavat omien
          liittymiensä olevan juuri niitä kaikkein parhaita ja edullisimpia.
          Samalta operaattorilta löytyy useita erilaisia liittymiä, joten moni
          saattaa väsyä vertailuun ja eri yksityiskohtien tutkiskeluun. Eikö
          olisikin kätevää, jos kaikki olisi kerrottu yhdessä artikkelissa?
        </TextArea>
        <TextArea>
          Saman operaattorin ja saman liittymän pitämisessä vuosikausia ei ole
          sinänsä mitään väärää, mikäli se vastaa omia tarpeita. Yksi
          tavallisimmista asioista kuitenkin on, että maksetaan joko
          palveluista, joita ei tarvita, tai liittymä ei sisällä jotain, mitä
          haluttaisiin tai tarvittaisiin. Tässä artikkelissa pyritään tarjoamaan
          vastaukset samassa paketissa kaikkiin niihin asioihin, joita
          puhelinliittymän valinnassa tulisi ottaa huomioon. Halvin
          puhelinliittymä saattaa monesti olla aivan erilainen kuin itse
          saattaisit ajatella.
        </TextArea>
        <TextArea>
          Ensimmäiseksi lähdetään liikkeelle siitä, mitä kaikkea tulisi ottaa
          huomioon puhelinliittymää valitessa. Monet liittymät näyttävät
          pikaisella vilkaisulla lähes identtisiltä, mutta niissä on kuitenkin
          eroja. Tämän vuoksi liittymän valintaan on hyvä käyttää hieman aikaa,
          jotta löytää varmasti sen itselle kaikkein sopivimman. Useimmiten on
          myös parempi nähdä hiukan vaivaa valinnassa etukäteen kuin ostaa niin
          sanotusti sika säkissä.
        </TextArea>
        <TextArea>
          Artikkelissa kerrotaan lisäksi, kuinka vertailla puhelinliittymiä ja
          löytää se kaikkein halvin puhelinliittymä netillä –{" "}
          <b>
            hinta kun on loppujen lopuksi yksi merkittävimmistä tekijöistä
            liittymää valitessa
          </b>
          . Tuskin kukaan haluaa maksaa ylimääräistä palvelusta tai tuotteesta,
          jos saman tuotteen saisi edullisemmalla hinnalla jostain muualta.
          Tämän vuoksi artikkeliin on koottu tietoa myös siitä, kuinka paljon
          puhelinliittymien hinnat todellisuudessa vaihtelevat, vai
          vaihtelevatko ne ylipäätään juurikaan.
        </TextArea>
        <Header3>Mitä pitää ottaa huomioon puhelinliittymää valitessa?</Header3>
        <TextArea>
          Kun suunnittelet uuden liittymän hankkimista, tulee sinun ensimmäisenä
          miettiä, minkälainen puhelimen käyttäjä itse olet sekä sitä, oletko
          ostamassa liittymää itsellesi,{" "}
          <Link to={"/lapselle/"}>lapsellesi</Link>, vai kenties useammalle
          perheenjäsenelle samanaikaisesti, tai onko vastuullesi annettu hankkia
          liittymät työpaikkasi tiimin jäsenille?{" "}
          <b>
            Entä tarvitsetko liittymän vain yhteen laitteeseen, vai kenties
            useampaan samanaikaisesti?
          </b>
          Perustarpeiden kartoittaminen ei ole yleensä se hankalin osuus
          liittymän valinnassa. Eri liittymät sisältävät hiukan erilaisia
          palveluita, hieman toisistaan poikkeavin hinnoin. Yleisesti ottaen
          liittymät koostuvat puheajasta, viesteistä sekä internetin käytöstä.
          Halvin puhelinliittymä netillä on jollekin rajaton liittymä kun
          jollekin toiselle se taas on käytön mukaan veloitettava
          puhelinliittymä.
        </TextArea>
        <Header3>Miten kilpailuttaa halvin puhelinliittymä netillä?</Header3>
        <TextArea>
          <b>Miten löytää halvin puhelinliittymä?</b> No kilpailuttamalla
          tottakai ja mikä parasta se on erittäin helppoa tämän sivuston avulla.
          Sinun tulee ainoastaa klikata itsesi tämän sivun ylälaitaan ja vastata
          4 helppoon kysymykseen. Näiden kysymysten perusteella vertailukoneemme
          on helppo löytää halvin puhelinliittymä juuri sinulle. Älä siis enään
          aikaile vaan aloita liittymien kilpailutus rullaamalla itsesi sivun
          ylälaitaan.
        </TextArea>
        <Header4>Miten minä käytän puhelinliittymääni?</Header4>
        <TextArea>
          Puheajan, viestien ja internetin osuus liittymissä vaihtelee, minkä
          vuoksi on tärkeää lähteä liikkeelle siitä, miten käytät puhelintasi.
          Soitatko paljon puheluita tavallisella puhelimella, vai käytätkö
          puheluihin mieluummin esimerkiksi Whatsappia tai Skypeä. Sama asia
          viestien lähetyksessä; lähetätkö niitä SMS-muodossa, vai käyttäen
          jotakin sovellusta. Tavallisten tekstiviestien lähetys on vähentynyt
          voimakkaasti viime vuosina erilaisten sovellusten, kuten Whatsappin
          yleistyttyä, joten viestipaketin osuuden liittymässä ei tarvitse
          yleensä olla kovin suuri. Monien mielestä tavallisia tekstiviestejä ei
          tarvitsisi välttämättä olla liittymäpaketissa ollenkaan.
        </TextArea>
        <Header4>Kuinka paljon tarvitsen nettiä?</Header4>
        <TextArea>
          Nettipaketin koko on monille nykypäivänä se ratkaisevin asia. Kuten jo
          edellä on mainittu, etenkin nuoret ja työikäiset ihmiset käyttävät
          pääosin puheluihin ja viesteihin nettiyhteyden vaativia sovelluksia,
          puhumattakaan kaikista muista sovelluksista ja sosiaalisesta mediasta,
          johon puhelinta käytetään. Puhelimen käyttäjän onneksi entisaikojen
          datakatot ovat nykyään enimmäkseen katoava muinaisjäänne, nykyisten
          liittymien sisältäessä lähes poikkeuksetta rajattoman netin käytön.
          Tämä on kuitenkin yksi tärkeimpiä asioita, johon tulee kiinnittää
          huomiota liittymää hankkiessa, sillä jollekin halvin puhelinliittymä
          on sellainen missä ainoastaan netti sisältyy hintaan ja muusta
          käytöstä veloitetaan käytön mukaan. Alla on listattuna muutamia
          muistettavia asioita liittymää valitessa:
        </TextArea>
        <TextArea>
          <ul>
            <li>
              Haluatko mieluummin kuukausimaksullisen vai prepaid-liittymän?
            </li>
            <li>Onko liittymässä datakatto vai rajaton netti?</li>
            <li>
              Määräaikainen vai toistaiseksi voimassa oleva sopimus?
              Määräaikaisen sopimuksen vaihtaminen kesken kauden voi olla
              haastavaa, mikäli et olekaan tyytyväinen.
            </li>
            <li>
              Kuinka liittymä toimii ulkomailla? Kaikki prepaidit eivät
              välttämättä toimi Suomen ulkopuolella.
            </li>
            <li>
              Oletko ostamassa pelkän liittymän vai sekä puhelimen, että
              liittymän samanaikaisesti? Joihinkin puhelimiin, etenkin
              osamaksullisiin, voi liittyä tarjouksena jokin tietty halvin
              liittymä.
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Omia mieltymyksiä kannattaa miettiä myös matkustelun kannalta.
          Joissakin liittymissä esimerkiksi netin käyttö Pohjoismaissa ja
          Baltiassa on rajatonta niin kuin kotimaassakin. Mikäli et juurikaan
          matkustele, ei välttämättä ole tarpeen hommata liittymää, jossa on
          suuri ulkomailla toimiva nettipaketti, sillä tämä vaikuttaa
          väistämättä hiukan myös hintaan. Toisaalta taas paljon matkustavalle
          ulkomaiden nettipaketin osuus voi olla juuri se kaikkein ratkaisevin
          asia, ja siihen kannattaakin silloin panostaa, niin säästyy monelta
          päänvaivalta, etenkin Euroopassa matkustaessa; osoitteet löytyvät
          kätevästi karttapalveluiden avulla, kun nettiyhteys on kunnossa.
        </TextArea>
        <TextArea>
          Liittymää valitessa huomioon otettavia asioita on siis useita. Hyvään
          alkuun kuitenkin pääsee kartoittamalla omat tarpeet liittymän osalta,
          ja miettimällä, mistä haluaa maksaa ja mistä ei. Määräaikaisia
          liittymiä parjataan paljon, mutta niissä saattaa kuitenkin olla etuja,
          joita ei toistaiseksi voimassa olevissa liittymätarjouksissa ole,
          joten joillekin ne sopivat. Toistaiseksi voimassa olevan liittymän
          etuna on sen vaihtamisen helppous; mikäli saat paremman tarjouksen
          muualta, käy siirtyminen usein kätevästi. Tämä on hyvä vaihtoehto
          etenkin heille, jotka tykkäävät pysyä ajan tasalla uusimmista
          tarjouksista ja ominaisuuksista.{" "}
          <b>
            Halvin puhelinliittymä netillä löytyy usein suosittuina
            "ale-päivillä", kuten Black Friday tai tammikuun alennukset.
          </b>
        </TextArea>
        <Header3>Paljonko liittymien hinnoissa on vaihtelua?</Header3>
        <TextArea>
          Liittymien hinnoissa on runsaastikin vaihtelua riippuen siitä,
          minkälainen liittymä on kyseessä ja mitä kaikkea halvin liittymä
          sisältää. Prepaid-liittymät ovat tavallisesti hyvin edullisia hankkia
          ja niissä ei ole kuukausimaksua, mutta puheluiden ja viestien hinnat
          voivat olla hiukan kalliimmat kuin kuukausimaksullisessa liittymässä.
          Prepaid-liittymissä on usein puheaikaa lähes yhtä suurella summalla
          kuin mitä liittymän hankkiminen maksaa, joten siinä mielessä se on
          edullinen vaihtoehto, ja sopiikin hyvin esimerkiksi lapsen
          ensimmäiseksi liittymäksi – siinä ei tarvitse huolehtia, että lasku
          pääsisi kasvamaan kohtuuttoman suureksi.
        </TextArea>
        <TextArea>
          Kuukausimaksullisissa liittymissä hinta riippuu siitä, kuinka paljon
          puhetta, viestejä, ja netin käyttöä liittymä sisältää. Nettipaketin
          koko tai sen rajattomuus vaikuttaa hintaan melkeinpä eniten. Useat
          operaattorit tarjoavat niin sanotusti eri kokoisia liittymiä
          erilaisille käyttäjille. Vähän puhelinta tarvitseva saa liittymänsä
          tietenkin edullisemmin kuin jatkuvasti netissä surffaava puhelimen
          käyttäjä. Edullisimmillaan liittymän saa noin kuudella eurolla kuussa,
          ja huippuunsa viritetyt,{" "}
          <b>
            kaiken mahdollisen sisältävät liittymät kustantavat noin 30-50 euroa
            kuukaudessa.
          </b>{" "}
          Vaihtelua hinnoissa siis löytyy, ja hintaan pystyy itse vaikuttamaan
          omilla valinnoilla. Se nykyinen liittymä ei välttämättä olekaan se
          halvin puhelinliittymä.
        </TextArea>
        <TextArea>
          Itse puheluiden ja viestien hinnoissa ei nykyään ole huomattavan
          suurta vaihtelua. Puhelut ja tekstiviestit maksavat keskimäärin 0,07
          €/minuutti ja tekstiviestit noin 0,07 €/kpl. Useissa liittymissä on
          nykyään myös rajattomasti puheluita ja tekstiviestejä. Liittymien
          hinnoitteluissa on usein tarjouksia, joissa hinta on esimerkiksi
          ensimmäisen vuoden ajan alhaisempi ja nousee sen jälkeen. Tämän vuoksi
          liittymän tarkistaminen vuosittain onkin suositeltavaa. Esimerkiksi
          DNA tarjoaa liittymiä, joissa ensimmäisen vuoden ajan hinta on tietty,
          ja ensimmäisen vuoden jälkeen noin 1-9 euroa kalliimpi kuukaudessa.
        </TextArea>
        <TextArea>
          Halvimmillaan ennakkoon maksettavan puhelinliittymän voi siis saada
          jopa alle viidellä eurolla, esimerkiksi{" "}
          <Link to={"/telia/"}>Telialta</Link> tai{" "}
          <Link to={"/dna/"}>DNA:lta</Link>. Aloituspaketissa on saldoa yleensä
          joko viidellä tai seitsemällä eurolla, ja veloitus tapahtuu joko
          käytetyn datan mukaan tai kiinteällä päivähinnalla.
          Prepaid-liittymissä on olemassa myös rajattoman netin käytön
          sisältäviä paketteja, joiden hinnat liikkuvat 25 euron molemmin puolin
          per kuukausi. Jälkikäteen laskutettavissa liittymissä hintaan
          vaikuttaa puhe- ja viestipaketin lisäksi myös netin nopeus.
          Edullisimmillaan perusliittymän saa esimerkiksi Telialta noin
          kahdeksalla eurolla kuussa, mutta netin nopeus on vain 0,256 Mbit/s.
          Mikäli et kuitenkaan tarvitse juurikaan nopeata nettiä, niin tämä voi
          olla sinulle se halvin puhelinliittymä. Nykyisen nettisi nopeuden voit
          mitata esim osoitteessa{" "}
          <a href="https://www.telia.fi/kauppa/kodin-netti/tee-nopeustesti">
            https://www.telia.fi/kauppa/kodin-netti/tee-nopeustesti
          </a>
        </TextArea>
        <TextArea>
          Mikäli ei tarvitse nettiä kovin paljoa, ja haluaa maksaa puheluista ja
          viesteistä käytön mukaan, voi tämän tyyppinen liittymä olla riittävä.
          Puhelinliittymien hinnoissa on siis melko paljon vaihtelua, riippuen
          siitä, minkä tyylisen liittymän haluat ja mitä ominaisuuksia siltä
          tarvitset. Suurin piirtein saman tyyppisissä, samoja ominaisuuksia
          sisältävissä liittymissä hintojen vaihtelu eri operaattorien välillä
          on usein vain muutamia euroja kuukaudessa, mutta kilpailu alalla on
          kovaa, joten tarjouksia kannattaa ehdottomasti seurata sekä olla
          tarpeen vaatiessa valmis päivittämään liittymäänsä.
        </TextArea>
        <Header2>Miten vertailla halvin puhelinliittymä?</Header2>
        <TextArea>
          Puhelinliittymien vertailu voi runsaasta tarjonnasta johtuen olla
          haastavaa ja aikaa vievää puuhaa. Mikäli on ehdottoman mieltynyt
          tietyn operaattorin palveluihin ja ei missään nimessä halua vaihtaa
          operaattoria, on kaikkein yksinkertaisin keino soittaa operaattorin
          asiakaspalveluun ja kysyä neuvoa sieltä. Monet operaattorit myös
          soittavat säännöllisesti asiakkailleen ja kartoittavat liittymän
          tilannetta, ja sitä, että palveleeko se vielä riittävästi sen hetkistä
          elämäntilannetta. Monesti liittymää hetken käytettyään osaa kertoa
          paremmin, vastaako se omia tarpeita vai pitäisikö jotain muuttaa, ja
          tässä asiakaspalvelu on oiva apu.{" "}
          <b>
            Halvin puhelinliittymä netillä on harvoin se 5 vuotta sitten avattu
            liittymä.
          </b>
        </TextArea>
        <TextArea>
          Asiakaspalvelijat saavat tiedot uusista liittymistä ensimmäisinä, ja
          heillä on tiedossaan koko yrityksen liittymävalikoima, joten he
          osaavat suositella parhaita tarjouksia ja etuja. Näin{" "}
          <Link to="/puhelinliittymavertailu">liittymien vertailu</Link> sujuu
          kätevästi puhelimessa, tai menemällä paikan päälle operaattorin
          myymälään. Asiakaspalvelijaa voi myös pyytää lähettämään tarjouksen
          vaikkapa sähköpostilla, mikäli haluaa jäädä vielä miettimään eri
          vaihtoehtoja. Kaikki eivät kuitenkaan välttämättä halua tämän tyylistä
          apua, tai haluavat itse suorittaa vertailun ilman henkilökohtaista
          neuvontaa. Liittymien vertailu onnistuu myös toki ilman
          asiakaspalvelun apua, esimerkiksi netissä.
        </TextArea>
        <TextArea>
          Toinen vaihtoehto vertailla eri liittymiä, on tutkia eri
          operaattoreiden verkkosivuja, joilla yrityksen eri tyyppiset liittymät
          hintoineen ovat esillä. Monilla operaattoreilla liittymien sisältämät
          ominaisuudet on kerrottu selkeästi, jolloin tietojen tarkastelu on
          helppoa. Kun tunnistaa omat tarpeensa liittymän suhteen, on helpompaa
          keskittyä vain niihin liittymiin, joissa nämä kyseiset ominaisuudet
          ovat, eikä koko yrityksen liittymärepertuaaria tarvitse kahlata
          sanasta sanaan läpi, sillä tämä voi olla hyvinkin aikaa vievää, koska
          jokaisella operaattorilla saattaa olla jopa toistakymmentä erilaista
          liittymää valikoimissaan.Tällä tavalla halvin puhelinliittymä ei
          löydykään aivan hetkessä.
        </TextArea>
        <TextArea>
          Kolmas tapa löytää halvin puhelinliittymä netillä, on käyttää
          erilaisia hakukoneita tai vertailusivustoja, joilla työ on niin
          sanotusti tehty jo valmiiksi. Erilaisten tuotteiden vertailuun
          kehitettyjä sivustoja on olemassa runsaasti. Alkuun pääsee
          kirjoittamalla Googleen "halvin puhelinliittymä", ja valitsemalla
          jonkin näistä vertailusivustoista. Sivustojen ulkoasut vaihtelevat,
          samoin se, kuinka eri liittymien ominaisuudet on tuotu esille, joten
          on omista mieltymyksistä kiinni, mitä näistä sivustoista haluaa
          käyttää. Vertailusivustot ovat hyvä tapa saada tietoa useasta
          tuotteesta samaan aikaan samalla näytöllä, mikä voi tehdä halvimman
          hinnan löytämisen helpommaksi.
        </TextArea>
        <TextArea>
          Vertailusivustoja käyttäessä voi kuitenkin olla mahdollista, että
          mikäli sivuston päivityksestä ei huolehdita riittävän ahkerasti,
          voivat tiedot olla vanhentuneita tai kaikki uusimmat tarjoukset ja
          kampanjat eivät ole esillä. Tämän vuoksi ne sopivat hyvin
          perustietojen vertailuun, mutta luotettavampaa ja varmasti
          ajankohtaista tietoa saa joko soittamalla operaattorin
          asiakaspalveluun, menemällä paikan päälle liikkeeseen, tai tutkimalla
          operaattorin verkkosivuja. Prepaid-liittymien osalta hintavertailua
          voi tehdä kätevästi menemällä esimerkiksi R-kioskin verkkosivuille tai
          paikan päälle, sillä R-kioskeilla on myynnissä melko kattava valikoima
          prepaid-liittymiä.
        </TextArea>
        <Header3>Mitä operaattoreita Suomessa toimii?</Header3>
        <Header5>DNA</Header5>
        <OperatorImage imgName={"dnaoperaattori.jpg"} />
        <TextArea>
          <b>DNA Oyj</b> on suomalainen operaattori, jolla on kaikkiaan yli 4
          miljoonaa liittymäasiakasta. DNA on toiminut vuodesta 2001 asti. DNA
          ilmoitti keväällä 2018 aloittaneensa päivittämään runkoverkkoaan
          5G-aikakauteen. Tammikuussa 2020 DNA:n 5G-verkko avattiin Helsingin
          ydinkeskustassa. DNA tarjoaa erilaisia liittymäpalveluita niin
          yksityisille kuluttajille kuin yrityksillekin. Itä- ja
          Pohjois-Suomessa DNA tekee yhteistyötä Telian kanssa, ja sen verkko
          kattaa koko Suomen. DNA-kauppa on yksi DNA:n toimialoista, ja sieltä
          voi ostaa sekä liittymiä, että laitteita. DNA-kauppoja löytyy
          kattavasti ympäri Suomea.
        </TextArea>
        <Header5>Elisa</Header5>
        <OperatorImage imgName={"elisaoperaattori.jpg"} />
        <TextArea>
          <b>Elisa Oyj</b> on johtava mobiiliverkko-operaattori Suomessa ja
          sillä on noin 6,2 miljoonaa liittymäasiakasta. Elisan
          yhteistyökumppaneita ovat muun muassa Telenor ja Vodafone.{" "}
          <Link to={"/elisa/"}>Elisa</Link> oli ensimmäinen, joka avasi
          kaupallisen 5G-verkon 27.6.2018 Tampereella ja Tallinnassa.
          Alkuvuodesta 2020 se laajensi 5G-verkkoaan Turkuun, Helsinkiin ja
          Jyväskylään.{" "}
          <a href="https://elisa.fi/5g/">Elisan 5G Ready -liittymiä</a> on myyty
          jo vuoden 2018 keväästä asti. Siinä datan maksimisiirtonopeus on 600
          megabittiä sekunnissa. Alla olevaan listaan on kerätty muutamia
          mielenkiintoisia yksityiskohtia Suomessa toimivista operaattoreista,
          mitä et välttämättä vielä ole tiennyt:
          <ul>
            <li>
              Telenor ilmoitti huhtikuussa 2020 ostavansa enemmistön DNA:sta
            </li>
            <li>
              DNA:n pisteissä on maksutonta puhdistaa puhelimen näyttö, ladata
              puhelinta tai kierrättää laitteet. Nämä ovat niin sanottuja
              pikapalveluita.
            </li>
            <li>
              Elisa sai vuoden 2020 tammikuussa Suomen Laatuyhdistyksen
              myöntämän Suomen laatupalkinnon, jonka allekirjoitti presidentti
              Niinistö. Palkinto annettiin yrityksessä tehdystä kehitystyöstä.
            </li>
            <li>Ruotsin valtio omistaa suurimman osan Telian osakkeista</li>
          </ul>
        </TextArea>
        <Header5>Telia</Header5>
        <OperatorImage imgName={"teliaoperaattori.jpg"} />
        <TextArea>
          <b>Telia Company AB</b> muodostui, kun Telia ja Sonera yhdistyivät
          vuonna 2002. Telian kotipaikkana on Tukholma. Telia toimii Suomen
          lisäksi myös Pohjoismaissa ja Baltiassa. Monissa Telian
          puhelinliittymissä onkin melko kattavat nettipaketit myös ulkomailla
          nettiä käytettäessä. Telialla on runsaasti ulkomaisia
          yhteistyökumppaneita, joka saattaa selittää osaltaan sen liittymien
          kattavan ja hyvän toimivuuden ulkomailla. Mikäli siis matkustelet
          paljon, ja käytät matkoillasi paljon nettiä, voi tarpeisiisi parhaiten
          sopiva halvin liittymä löytyä Telialta, sillä Telian perusliittymätkin
          sisältävät melko hyvän nettipaketin EU/ETA-alueelle.
        </TextArea>
        <Header5>Moi mobiili</Header5>
        <OperatorImage imgName={"moioperaattori.jpg"} />
        <TextArea>
          <b>Moi Mobiili Oy</b> aloitti toimintansa alun perin
          virtuaalioperaattorina, mikä tarkoitti, että se vuokrasi verkkoa
          DNA:lta. Moi on perustettu vuonna 2016, ja sen omistaa nykyään DNA.
          Moin liittymien toimintaperiaate on niin sanottu poolihinnoittelu,
          missä datakiintiö useamman SIM-kortin välillä yhdistetään ja jaetaan.
          Tämä tarkoittaa, että mikäli sinulla on esimerkiksi puhelimen lisäksi
          tabletti käytössä, näillä on yhteinen datakiintiö. Datakiintiön
          täyttymistä on mahdollista seurata mobiilisovelluksella
          reaaliaikaisesti. Mikäli haluaa laajentaa palveluita, on mahdollista
          ostaa lisää SIM-kortteja. Aiemmin SIM-korttien määrä oli aluksi
          automaattisesti kolme, mutta nykyään sen voi itse päättää. Monesti
          juuri Moi on se halvin puhelinliittymä kun puhelimen käyttö koostuu
          lähinnä kohtalaisesta netin käytöstä.
        </TextArea>
        <Header2>Perusliittymät</Header2>
        <TextArea>
          Perusliittymillä tarkoitetaan tässä sellaisia liittymiä, joissa ei ole
          mukana nettiä, tai sen osuus on hyvin pieni. Perusliittymällä voi
          hoitaa puhelunsa ja tekstiviestit, sekä hyvin vähäistä netin käyttöä.
          Tässä kerrotaan eri operaattoreiden tarjoamista perusliittymistä, mitä
          ne sisältävät ja mihin hintaan. Eri operaattoreiden tarjoukset ja
          kampanjat voivat muuttua, mutta tämän artikkelin avulla saat
          peruskäsityksen siitä, minkälaisia liittymiä tällä hetkellä on
          tarjolla. Liittymien ajankohtainen tilanne ja hinnat kannattaa
          kuitenkin aina tarkistaa operaattorin sivuilta ennen lopullisen
          ostopäätöksen tekemistä.
        </TextArea>
        <TextArea>
          <b>DNA Puhe Plus</b> liittymä on DNA:n perusliittymä, jossa puhelut ja
          viestit laskutetaan käytön mukaan niin kotimaassa kuin
          EU/ETA-alueellakin. Puhelut maksavat 0,07 €/minuutti ja tekstiviestit
          0,07 €/ kappale. Netissä maksiminopeus on 0,256 megabittiä sekunnissa.
          Liittymässä on myös 2 gigatavun EU-datapaketti/kuukausi, joka sisältyy
          liittymän kuukausihintaan. Puheluista ja viesteistä EU/ETA-alueella
          laskutetaan sama hinta kuin kotimaassakin. DNA Puhe Plus liittymässä
          on automaattisesti aktivoituva WiFi-puhelutoiminto matkustettaessa
          EU/ETA-maiden ulkopuolella, joka tarkoittaa, että puhelut laskutetaan
          niin kuin ne olisi soitettu kotimaasta. Liittymän avausmaksu on 3,90 €
          ja kuukausihinta 7,90 €.
        </TextArea>
        <TextArea>
          <b>Elisan perusliittymä</b> on nimeltään <b>Saunalahti Tarkka Mini</b>
          . Elisan kuluttaja-asiakkaille tarjoamat liittymät kulkevat yleisesti
          Saunalahti-nimen alla. Saunalahti Tarkka Minissä puhelut maksavat
          0,069 euroa per minuutti ja tekstiviesteistä veloitetaan saman verran
          per kappale. Suomesta maan rajojen ulkopuolelle soitettaessa määräytyy
          maksu erillisen ulkomaanpuheluille tarkoitetun hinnaston mukaisesti.
          Netissä ei ole käyttökattoa, ja sen nopeus on 0,256 megabittiä
          sekunnissa. Liittymän kuukausihintaan sisältyy 2 gigatavun
          nettipaketti EU/ETA-maissa. Saunalahti Tarkka Mini maksaa 7,90 euroa
          kuukaudessa ja sen avausmaksu on 4,90 euroa. Tällainen liittymä voi
          olla halvin puhelinliittymä kun puhelimella ei ole hirveästi käyttöä.
        </TextArea>
        <TextArea>
          <b>Telian perusliittymä</b> on nimeltään <b>Rajaton Lite 0,2 M</b>.
          Viestit maksavat 7 senttiä kappale ja puhelut seitsemän senttiä per
          minuutti. Netin maksiminopeus on 0,256 megabittiä sekunnissa, ja sen
          käyttö on rajatonta. Pohjolassa, Baltian maissa ja EU/ETA-maissa on
          nettiä käytettävissä 3 gigatavua kuukaudessa, joka on enemmän kuin
          kilpailijoiden tarjoamissa perusliittymissä. Rajaton Lite 0,2 M:n
          avaaminen maksaa kolme euroa, ja kuukausittain laskutettava summa on
          7,90 euroa. Liittymällä ei ole määräaikaisuutta, mikä tarkoittaa, että
          sen voi irtisanoa halutessaan milloin tahansa.
        </TextArea>
        <TextArea>
          <b>Moi Mobiilin Kuuden euron simmi</b> on Moi Mobiilin tarjoama
          perusliittymä, jossa puhelut maksavat 0,055 euroa minuutti ja
          tekstiviestit 0,055 euroa kappale. Liittymän nimestäkin voi päätellä
          kuukausimaksun olevan kuusi euroa. SIM-korttien toimituksesta
          veloitetaan viisi euroa, ja puheluiden ja viestien luvataan
          maksimissaan kustantavan kahdeksan euroa kuukaudessa. Netti toimii
          nopeudella 100 megabittiä sekunnissa, mutta siinä on neljän gigatavun
          käyttökatto. Mikäli tuo määrä ei riitä, lisää hintaa tulee 2,5 euroa
          per gigatavu. Ulkomailla käytössä on yhden gigatavun verran EU-roaming
          dataa. Tämä liittymä on meidän mielestä monelle se halvin
          puhelinliittymä.
        </TextArea>
        <Header3>Hieman kattavammat liittymät</Header3>
        <TextArea>
          Perusliittymien vertailu on melko yksinkertaista, sillä jokaisella
          operaattorilla on se yksi perusliittymä, joten vaihtoehtoja on vain
          muutama. Kun liittymältä aletaan hakea hiukan enemmän, alkaa myös
          valinnanvara kasvaa merkittävästi. Näissä liittymätyypeissä on myös
          useasti muuttuvia hintoja ensimmäisen vuoden jälkeen. Moi Mobiililla
          on tällä hetkellä valikoimissaan perusliittymän lisäksi kaksi muuta
          liittymätyyppiä: <b>Rajattoman Reilu</b> ja <b>Rajaton Turbo</b>.
          Molemmissa puheluiden ja viestien hinnat ovat samat kuin
          perusliittymässäkin, eli 0,055 euroa minuutti/kappale, mutta datan
          nopeus vaihtelee. Alla olevasta listasta löydät kumpaisenkin tiedot:
          <ul>
            <li>
              Moi Rajattoman Reilussa on käytössä nettiyhteys nopeudella 100
              megabittiä sekunnissa
            </li>
            <li>
              Moi Rajaton Turbossa on nopeudella 300 megabittiä sekunnissa
              toimiva netti
            </li>
            <li>Moi Rajaton Reilu -liittymä maksaa 18 euroa kuukaudessa</li>
            <li>Moi Rajaton Turbo -liittymä maksaa 23 euroa kuukaudessa</li>
            <li>
              Molemmissa tulee lisäksi maksimissaan 4 euroa kuukaudessa lisää
              hintaa normaalihintaisista kotimaan puheluista. Palvelunumeroihin
              soittamisesta veloitetaan erikseen.
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Elisalta löytyy valinnanvaraa joko käytön mukaan veloitettavien
          liittymien tai rajattomat puhelut sekä viestit sisältävien liittymien
          muodossa. Rajattomat liittymät kulkevat nimellä Huoleton, kun taas
          käytön mukaan veloitettavat kuuluvat Tarkka-tuoteperheeseen. Netin
          nopeudessa voi valita 0,25 megabitistä sekunnissa aina 1 000
          megabittiin sekunnissa. Elisa mainostaa sen suosituimman liittymän
          olevan <b>Saunalahti Huoleton Premium 150</b>, jossa netti toimii 150
          megabitin nopeudella sekunnissa, ja puheluita ja viestejä kotimaassa
          sisältyy rajattomasti kuukausihintaan. Liittymän hinta on ensimmäisen
          vuoden ajan 29,90 euroa kuussa, minkä jälkeen se on 33,90 euroa
          kuussa.
        </TextArea>
        <TextArea>
          Telian liittymät jaetaan Rajaton ja Rajaton Lite -ryhmiin, joissa
          Rajattomissa puhe- ja viestipaketit sisältyvät hintaan, Liteissä ne
          maksavat käytön mukaan. Netin nopeuden voi valita 0,256 megabittiä
          sekunnissa ja 450 megabittiä sekunnissa väliltä. Rajaton 200 M
          -liittymässä netti toimii nopeudella 20 megabittiä sekunnissa, ja
          kuten nimestä voidaan päätellä, puhelut ja viestit kotimaassa kuuluvat
          hintaan. EU/ETA-alueella nettiä voi käyttää samaan hintaan 15
          gigatavua kuukaudessa. <b>Telia Rajaton 200 M</b> liittymä maksaa
          ensimmäisen vuoden ajan 29,90 euroa kuukaudessa, minkä jälkeen hinta
          on 33,90 euroa kuukaudessa.
        </TextArea>
        <TextArea>
          DNA:n liittymät jaetaan Rajattomiin ja Jämpteihin, riippuen siitä,
          kuuluvatko puhelut ja viestit kuukausihintaan vai veloitetaanko ne
          erikseen. Netin maksiminopeus näissä liittymissä vaihtelee 0,265
          megabittiä sekunnissa ja 450 megabittiä sekunnissa välillä. DNA Nopea
          4G Rajaton Plus sisältää rajattomasti puheluita ja viestejä
          kotimaassa, ja EU/ETA-alueella ja nettiä voi kuukausihinnalla käyttää
          EU/ETA-alueella 10 gigatavua kuukaudessa. Netin maksiminopeus on 150
          megabittiä sekunnissa. <b>DNA Nopea Rajaton Plus</b> -liittymä maksaa
          ensimmäisen vuoden ajan 28,90 euroa kuukaudessa, minkä jälkeen se
          maksaa 34,90 euroa kuukaudessa.
        </TextArea>
        <Header3>Kaiken sisältävät liittymät</Header3>
        <TextArea>
          Kaiken sisältävissä liittymissä on rajaton määrä puheluita,
          tekstiviestejä ja nettiä kotimaassa. Erikseen veloitettavia ovat
          kuitenkin puhelut maksullisiin numeroihin ja palvelunumeroihin. Muista
          operaattoreista poiketen Moi Mobiililla ei ole tällä hetkellä
          valikoimissaan ollenkaan kaiken sisältävää liittymää, vaan heidän
          valikoimissaan olevissa liittymissä puhelut ja viestit maksavat aina
          käytön mukaan tiettyyn rajaan asti, jonka yli hinta ei
          normaalihintaisissa puheluissa ja viesteissä nouse. Kaiken
          sisältävissä liittymissä valittavana on siis tällä hetkellä liittymät
          DNA:lta, Telialta ja Elisalta (yksityisasiakkaille nimellä
          Saunalahti). Tällainen liittymä on halvin puhelinliittymä netillä
          sellaiselle henkilölle, joka käyttää aktiivisesti puhelintaan ja
          tarvitsee jatkuvasta nopeaa nettiä.
        </TextArea>
        <TextArea>
          <b>DNA Max 4G Rajaton</b> on DNA:n kaikkein kattavin liittymä, ja se
          sisältääkin lähes kaiken, mitä liittymältään voi haluta.
          Kuukausimaksuun kuuluu rajoittamaton määrä puheluita, tekstiviestejä
          ja netissä surffausta verkon maksiminopeudella. Kuukausimaksuun
          kuuluvat myös normaalihintaiset puhelut ja tekstiviestit sekä
          multimediaviestit EU/ETA-maissa. Nettiä voi käyttää ulkomailla 20
          gigatavua kuukaudessa. Tähänkin DNA:n liittymään kuuluvat myös
          EU-alueen ulkopuolella toimivat WiFi-puhelut. DNA Max Rajaton maksaa
          49,90 euroa kuukaudessa, ja liittymän avaamisesta veloitetaan 3,90
          euroa. Hinta on sama riippumatta asiakkuuden kestosta.
        </TextArea>
        <TextArea>
          Telian kattavin liittymä on nimeltään Rajaton 450 M. Puheluita ja
          viestejä on rajattomasti, samoin kuin surffausta 450 megabitin
          nopeudella sekunnissa. Maksimilatausnopeus on 450 megabittiä
          sekunnissa ja maksimilähetysnopeus on 100 megabittiä sekunnissa.
          <b>Telian Rajaton 450 M</b> on myös liittymä, johon on tulevaisuudessa
          saatavilla <b>5G-verkko</b>. Netin rajaton käyttö kuuluu
          kuukausimaksuun Baltiassa ja Pohjoismaissa. Muualla EU/ETA-alueella on
          käytössä 20 gigatavua kuukaudessa. Liittymän hinta on ensimmäisten 12
          kuukauden ajan 39,90 euroa kuussa, minkä jälkeen se on 44,90 euroa
          kuussa.
        </TextArea>
        <TextArea>
          Elisan kattavin liittymä on Saunalahti Huoleton 5G 1000M. Rajattomien
          puheluiden ja viestien lisäksi liittymässä on jopa nopeudella 1000
          megabittiä sekunnissa toimiva rajaton 5G-mobiililaajakaista
          kotimaassa, Pohjolassa ja Baltiassa. Myös videopuheluita on käytössä
          rajattomasti. EU/ETA-maissa dataa on käytettävissä 23 gigatavua
          kuukaudessa. <b>Saunalahden 5G-liittymät</b> tukevat myös
          WiFi-puheluita. Saunalahti Huoleton 5G 1000M-liittymällä voi kerryttää
          myös S-ryhmän bonuksia. Liittymän hinta on 49,90 euroa kuukaudessa.
          Liittymän avaaminen maksaa 4,90 euroa ja se ei ole määräaikainen,
          joten vaihtaminen tai irtisanominen onnistuu koska tahansa.
        </TextArea>
        <Header3>Prepaid-liittymät</Header3>
        <TextArea>
          Prepaid-liittymä tarkoittaa liittymää, jossa puheaikaa ladataan
          ennakkoon liittymälle. Niihin on mahdollista valita myös erilaisia
          datapaketteja, jotka ovat käteviä etenkin paljon nettiä käyttäville.
          <Link to={"/prepaid/"}>Prepaid-liittymiä</Link> pystyy ostamaan samaan
          tapaan kuin jälkikäteen laskutettaviakin liittymiä, eli esimerkiksi
          operaattoreiden verkkosivuilta tai liikkeistä, ja tämän lisäksi myös
          R-kioskeilta. Operaattoreista vain Moi Mobiililla ei ole
          valikoimissaan ollenkaan prepaid-liittymiä, vaan heillä kaikki
          laskutus tapahtuu käytön mukaan jälkikäteen. Muilta operaattoreilta
          löytyy sen sijaan useampia erilaisia prepaid-liittymiä – tarkastellaan
          niitä ja niiden eroja lähemmin seuraavissa kappaleissa.
        </TextArea>
        <TextArea>
          Saunalahti Prepaid -aloituspakkauksessa on kuudella eurolla puheaikaa.
          R-kioskien ja Elisan myymälän lisäksi liittymiä myyvät myös S- ja
          K-ryhmien kaupat sekä huoltoasemat Shell ja St1. Liittymän
          peruspalveluihin kuuluu puheluiden ja videopuheluiden, teksti- ja
          multimediaviestien sekä netin lisäksi muun muassa ryhmäpuhelu-,
          välipuhelu-, numeronnäytön esto- ja datapalvelu. Liittymää voi käyttää
          myös ulkomailla. Ennen ensimmäistä latausta liittymä on voimassa kolme
          kuukautta, ja jokaisen latauksen jälkeen 12 kuukautta. Runsaasti
          nettiä käyttäville on saatavilla myös erillisiä nettipaketteja.
          Liittymän normaali hinta on 5,90 euroa.
        </TextArea>
        <TextArea>
          Telian prepaid-liittymällä maksat puheluista ja viesteistä 0,066
          €/minuutti tai kappale ja netin käytöstä 0,01 €/megatavu,
          maksimiveloituksen ollessa kuitenkin 0,99 € päivässä. Liittymässä on
          ostohetkellä 7 eurolla alkusaldoa, ja lisäksi ensimmäisestä
          latauksesta saa 5 eurolla bonussaldoa, jota voi käyttää
          normaalihintaisiin puheluihin ja viesteihin sekä netin käyttöön.
          Aloituspakkauksessa ovat mukana kaikki SIM-korttikoot. Netin
          maksiminopeus on 100 megabittiä sekunnissa. Telian prepaid ei toimi
          ulkomailla, joten tämä kannattaa ottaa huomioon liittymää ostaessa.
          Telian prepaid-liittymään on mahdollista ladata myös erillisiä
          nettipaketteja.
        </TextArea>
        <TextArea>
          <b>DNA Super Prepaid</b> sisältää saldoa viidellä eurolla. Netin
          maksiminopeus on 4G-verkossa 300 megabittiä sekunnissa. Puhelut ja
          viestit maksavat kuusi senttiä minuutti/kappale. Netin käyttö maksaa
          0,99 euroa päivässä, riippumatta käytön määrästä.
          Verkkovierailupuheluista ja -tekstiviesteistä veloitetaan
          EU/ETA-alueella sama hinta kuin Suomessakin. DNA:lla on olemassa myös
          erillinen mobiilisovellus, jonka asentamalla saa käyttöönsä etuja,
          joita ei muuten saa. Sovelluksen tutustumisetuna saa yhden päivän
          ilmaisen datayhteyden. Liittymässä on myös erityinen turvaominaisuus,
          joka tarkoittaa, että vaikka saldo loppuisi, voit soittaa vielä
          kahteen ennalta asetettuun numeroon kolmen minuutin puhelut.
        </TextArea>
        <TextArea>
          DNA:n toinen prepaid-liittymä on nimeltään DNA Rajaton Prepaid, ja
          siinä veloitus tapahtuu päiväkohtaisesti. Hinta on 0,89 euroa
          päivässä, sisältäen rajattomat kotimaan sekä EU/ETA-alueen
          verkkovierailupuhelut ja -viestit sekä netin käytön Suomessa.
          Liittymälle tulee kuukaudessa hintaa yhteensä 26,70 euroa. Liittymä on
          myös mahdollista laittaa tauolle, mikäli tietää, että ei aio käyttää
          sitä pitkään aikaan. Tällöin saldo ei kulu turhaan, sillä muuten
          liittymää veloitetaan riippumatta siitä, käyttääkö puhelinta vai ei.
          Netin käyttö maksaa EU/ETA-alueella 0,0056 euroa megatavulta.
        </TextArea>
        <Header3>
          Mikä ihmeen roaming – pitääkö puhelin sammuttaa ulkomailla?
        </Header3>
        <TextArea>
          Vaikka tietoisuus puhelinliittymien ominaisuuksista, samoin kuin
          puhelintenkin ominaisuuksista, on kasvanut, ja matkailu on yhä
          suositumpaa, ei kaikille välttämättä edelleenkään ole täysin selvää,
          mikä on roaming, johon törmää aina matkustaessa. Puhelinliittymistä
          puhuttaessa se kuuluu melko olennaisesti asiaan, joten perehdytäänpä
          siihen hiukan. Verkkovierailu, eli roaming, ei ole enää samanlainen
          mörkö kuin takavuosina, jolloin monesti oli todella järkevämpää
          sammuttaa koko puhelin ulkomaille matkustettaessa jättimäisen
          puhelinlaskun pelossa. Silti on hyvä ymmärtää, mitä roaming tarkoittaa
          ja kuinka se toimii, sillä halvin puhelinliittymä ei olekaan enään se
          halvin, jos se ei sisällä paljon matkaavalle tarpeesi roaming dataa.
        </TextArea>
        <TextArea>
          Roaming siis tarkoittaa, kuten edellisessä kappaleessa jo
          sivuttiinkin, verkkovierailua. Verkkovierailu puolestaan tarkoittaa
          sitä, että sinulla on jonkun tietyn operaattorin puhelinliittymä,
          jonka tarjoamia palveluita yleensä käytät sen verkossa. Kun menet
          matkalle toiseen maahan sen kantaman ulkopuolelle, käytätkin näitä
          oman operaattorisi tarjoamia palveluita jonkun toisen operaattorin
          verkossa, eli olet niin sanotusti vierailuverkossa. Roamingia on siis
          kaikki se toiminta, mitä teet puhelimellasi lomamatkalla Suomen
          rajojen ulkopuolella. Alla olevassa listassa on muutamia roamingiin
          liittyviä asioita, jotka sinun on hyvä tietää:
          <ul>
            <li>
              Roaming pitää sisällään myös vastaanotetut puhelut ja viestit
            </li>
            <li>
              Roamingiin EU/ETA-alueella ei vaikuta se, mikä operaattori
              toisella osapuolella on käytössään
            </li>
            <li>
              Voit ostaa prepaid-liittymällesi lisäarvoa myös roamingin aikana
            </li>
            <li>
              Mikäli prepaid-liittymän dataverkkovierailuille on asetettu
              yläraja, tulee tämän rajan olla vähintään yhtä suuri kuin
              prepaid-kortilla jäljellä oleva saldo on jaettuna 4,50 eurolla.
              Jakolasku tehdään sillä hetkellä, kun alat käyttää roamingia
            </li>
          </ul>
        </TextArea>
        <TextArea>
          Euroopan Unionin sääntöjen uudistuttua ei roamingista enää tarvitse
          maksaa ylimääräisiä maksuja, vaan voit huoletta käyttää puhelintasi
          matkustaessasi toiseen maahan Euroopan Unionin alueella samalla
          hinnalla kuin käyttäisit sitä kotimaassakin. Nämä uudistuneet EU:n
          säännöt koskevat puheluita niin kiinteisiin numeroihin kuin
          matkapuhelimiinkin, samoin kuin tekstiviestejä sekä netin käyttöä. Oma
          operaattorisi laskuttaa sinua puhelimen käytöstäsi ulkomailla saman
          hinnoittelumallin mukaisesti kuin se tekee normaalistikin ollessasi
          Suomessa. Mutta entä siinä tapauksessa, että sinulla onkin käytössäsi
          prepaid-liittymä, pätevätkö samat säännöt edelleen?
        </TextArea>
        <TextArea>
          Kyllä, periaatteessa pätevät, pienin poikkeuksin – tämä riippuu hiukan
          käytössä olevasta prepaid-liittymästä ja sen laskutustavasta. Euroopan
          Unionin säännöt pätevät sellaisenaan, mikäli liittymäsi laskutus on
          esimerkiksi vuorokausikohtaista, eikä perustu käytetyn datan määrään,
          eli esimerkiksi rajattomissa prepaid-liittymissä asiasta ei tarvitse
          huolehtia. Verkkovierailun kotimaan hintoja sovelletaan siis myös
          prepaid-liittymissä, mutta toisinaan operaattorilla voi olla käytössä
          myös dataraja. Datarajan käyttö tulee ajankohtaiseksi silloin, kun
          laskutus perustuu käytettyyn dataan, ja samaan aikaan datan hinta on
          kotimaassasi alle 4,50 euroa gigatavulta.
        </TextArea>
        <TextArea>
          EU:n alueella puhelinta ei siis tarvitse sammuttaa suurten laskujen
          pelossa; kuinka on EU:n ulkopuolelle matkustettaessa? Valitettavasti
          tähän ei ole olemassa tyhjentävää vastausta, vaan se riippuu maasta,
          jonne olet matkustamassa.Nykyään kuitenkin se jopa se halvin
          puhelinliittymä sisältää ainakin kohtuulliset kulut roaming datasta.
          Ainut yleispätevä ohje on, että asia kannattaa tarkistaa ennen matkaa.
          EU:n ulkopuolella hinnat voivat vaihdella paljonkin, ja toisissa
          maissa roaming on kallista. Toinen huomionarvoinen asia on laivalla
          matkustaminen. Vaikka laiva matkaisi kahden ETA-alueeseen kuuluvan
          maan väliä, on silti mahdollista, että matkalla mobiilipalveluja
          tarjotaan satelliitin kautta, jolloin ei ole olemassa mitään sääntelyä
          tai kattoa hintojen suhteen.
        </TextArea>
        <Header2>Mikä on minulle halvin puhelinliittymä?</Header2>
        <TextArea>
          Mikä kellekin on kaikista halvin puhelinliittymä, riippuu siitä,
          minkälainen puhelimen käyttäjä kukakin on, ja millaisia tarpeita
          käyttäjällä on liittymän suhteen. Mikäli katsotaan pelkästään
          hintalappua liittymän kohdalla, on halvin vaihtoehto hyvin
          todennäköisesti prepaid-liittymä, sillä siinä ei ole kuukausimaksuja,
          ja puhelut ja viestit ovat suunnilleen saman hintaisia kuin
          jälkikäteen laskutettavissa liittymissä. Lisäksi osassa
          prepaid-liittymiä on olemassa erilaisia bonusjärjestelmiä, jotka
          kasvattavat saldoa latauksen yhteydessä tai liittymisetuna, jolloin
          pelkän avauspaketin sisältämällä saldolla tai ensimmäisellä
          latauksella voi puhua pitkäänkin.
        </TextArea>
        <TextArea>
          Prepaidin peruspaketilla ei netistä tarvitse maksaa erikseen, jos
          kokee, että sitä ei käytä tai käyttää vain hyvin harvoin. Mikäli
          ajattelee, että ei halua maksaa netistä liittymän yhteydessä, on
          prepaid-liittymä melkeinpä ainoa, joka ei automaattisesti sisällä
          netin käyttöä. Toiselta kantilta asiaa katsoessa taas lähes kaikkiin
          edullisiin perusliittymiinkin sisältyy nykyään jo rajaton – vaikkakin
          usein hidas – netti, joten asialla ei ole sinänsä suurta merkitystä.
          Eli mikäli et tarvitse nettiä puhelimessa ja et kuitenkaan halua ostaa
          prepaid-liittymää syystä tai toisesta, kannattaa valita jokin
          perusliittymä.
        </TextArea>
        <TextArea>
          Perusliittymissä on monesti rajaton netti, mutta niiden hitaampia
          yhteys tekee niistä edullisempia. Perusliittymissäkin on
          kuukausimaksu, mutta se on alimmillaan 6-7 euroa. Tämän lisäksi niissä
          maksetaan myös puheluista ja viesteistä erikseen, joten vähän puhuvan
          ja nettiä tarvitsemattoman kannattaa vakavasti harkita
          kuukausimaksuttoman prepaid-liittymän hankkimista. Sen saa
          halvimmillaan alle viidellä eurolla, ja usein saldoa on
          aloituspaketissa enemmän, kuin mitä koko liittymä maksaa. Mikäli
          puolestaan haluat mahdollisimman paljon mahdollisimman edullisesti, on
          taloudellinen vaihtoehto todennäköisesti päätyä johonkin liittymään,
          joka yhdistelee kaikkia ominaisuuksia.
        </TextArea>
        <TextArea>
          Tämä tarkoittaa, että jos et tarvitse kaikkein nopeinta nettiä, ja
          olet niin sanotusti peruskäyttäjä, tulet todennäköisesti pärjäämään
          melko hyvin netillä, jonka maksiminopeus on 150-200 megabittiä
          sekunnissa. Tämä laskee liittymän kuukausittaista hintaa huomattavasti
          verrattuna kaikkein nopeimpiin liittymiin, jotka tarjoavat 450
          megabitistä sekunnissa ylöspäin nousevia nopeuksia. Esimerkiksi Telian
          Rajaton 100 M on hyvä perusliittymä, jossa on nopeudella 100
          megabittiä sekunnissa toimiva netti, 120 kappaletta viestejä/minuuttia
          puhetta, ja 12 gigatavun EU-datakäyttö. Hintaa tällä liittymällä on
          ensimmäisen vuoden ajan 26,90 euroa kuussa.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Kuten tämän artikkelin luettuasi varmasti voit todeta,
          puhelinliittymiin ja niiden valintaan liittyy paljon erilaisia
          asioita. Tässä jutussa on pyritty selventämään näitä asioita, niin
          että seuraavan kerran liittymää valitessasi sinulla olisi melko hyvä
          peruskäsitys markkinoilla olevista tuotteista sekä siitä, mitkä asiat
          vaikuttavat liittymän hintaan, kuinka toimia puhelimen kanssa
          ulkomaille matkustettaessa ja kuinka oma liittymä käyttäytyy
          matkoilla.
        </TextArea>
        <TextArea>
          Prepaid-liittymät jakavat mielipiteitä. Hyvinä puolina on edullinen
          hinta ja helppo saatavuus sekä huolettomuus. Huonoina puolina taas
          voidaan pitää liittymän latauksen muistamista. Muutenkin
          puhelinliittymät ovat melko henkilökohtaisten mieltymysten mukainen
          asia. Se mikä sopii toiselle täydellisesti, voi toiselle olla liikaa
          tai liian vähän. Hyvä puoli on, että valinnanvaraa riittää, ja
          liittymän vaihtaminen tai päivittäminen on nykyään todella helppoa.
          Prepaid voi olla myös halvin liittymä, jos liittymän käyttö on
          erittäin epäsäännöllistä.
        </TextArea>
        <TextArea>
          <Link to="/operaattorit">Operaattorit</Link> pyrkivät jatkuvasti
          kehittämään palveluitaan ja tuotteitaan sekä olemaan edellä
          kilpailijoitaan. Asiakkaat voivat nauttia tästä kilpailusta laskevien
          hintojen ja uusien tuotteiden muodossa.{" "}
          <b>
            Nyt jo voi saada esimerkiksi SIM-kortin e-muodossa, jolloin
            varsinaista muovista korttia ei enää tarvitse
          </b>
          . Monet operaattorit myös tarjoavat edullisempia liittymähintoja
          nuorille aikuisille, joten mikäli olet alle 28-vuotias, kannattaa
          pitää silmät auki myös näiden tarjousten osalta.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(sort: { fields: price }) {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
