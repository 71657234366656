import React from "react"
import styled from "styled-components"
import { device } from "../../../utils/mediaQueries"

const Header = styled.h5`
  padding: 0rem 16rem;
  @media ${device.tablet} {
    padding: 0.2rem;
  }
`

const Header5 = props => {
  return <Header>{props.children}</Header>
}

export default Header5
