import React from "react"
import styled from "styled-components"
import { device } from "../../../../utils/mediaQueries"

const Link = styled.a`
  text-decoration: none;
  color: blue;
  font-size: 1.1rem;
  padding: 0.7rem 0rem;
  width: 100%;
  @media ${device.tablet} {
    font-size: 1.2rem;
    padding: 0.2rem;
    font-weight: 800;
  }
`
const SmallText = styled.p`
  font-size: 0.9rem;
  font-weight: 200;
`

const Container = styled.div`
  background: #edeeff;
  padding: 1.3rem;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h5`
  font-size: 0.8rem;
  font-weight: 500;
`

const trackClick = () => {
  typeof window !== "undefined" &&
    window.gtag("event", `AdClick`, {
      event_label: "appleAd",
      event_category: "Apple",
    })
}

const MacAd = () => (
  <Container>
    <Title>Kaikki Apple tuotteet jopa -50% alkuperäisestä hinnasta</Title>
    <SmallText>
      Käytetty Apple tuote on fiksu ja vastuullinen valinta. Et säästä
      pelkästään itse useita satoja euroja, vaan ostamalla kätetyn MacBookin,
      iPadin tai iPhonen säästät myös luontoa. Tarkista siis käytettyjen
      tuotteiden valikoima ja nappaa parhaat tuotteet ensimmäisenä.
    </SmallText>
    <LinkContainer>
      <Link
        onClick={() => trackClick()}
        href="/blogi/mista-ostaa-kaytetty-macbook/"
        target="_blank"
      >
        Käytetty MacBook 12kk takuulla {">>"}
      </Link>
      <Link
        onClick={() => trackClick()}
        href="/blogi/mista-ostaa-kaytetty-iphone/"
        target="_blank"
      >
        Käytetty iPhone 12kk takuulla {">>"}
      </Link>
      <Link
        onClick={() => trackClick()}
        href="/blogi/mista-ostaa-kaytetty-ipad/"
        target="_blank"
      >
        Käytetty iPad 12kk takuulla {">>"}
      </Link>
      <Link
        onClick={() => trackClick()}
        href="/blogi/mista-ostaa-kaytettyja-apple-tuotteita/"
        target="_blank"
      >
        Kaikki Apple tuotteet 12kk takuulla {">>"}
      </Link>
    </LinkContainer>
  </Container>
)

export default MacAd
